import { graphql } from "gatsby";
import * as React from "react";
import styled from "styled-components";

import { unwrapFirst } from "../util";
import {
  Layout,
  Hero,
  HeroCoverBackground,
  HelmetSeo,
  HorizontalContainer,
  Section,
  FormattedRichText,
  SectionQuotes,
} from "../components/legacy";
import HERO_BACKGROUND_1 from "../../img/ui/patterns/hero_background_1.svg";
import SECTION_BACKGROUND_DARK_1 from "../../img/ui/patterns/section_background_dark_1.svg";
import { Color } from "../constants/legacy";

type Props = {
  data: GatsbyTypes.ComparisonTablePageQuery;
};

export const query = graphql`
  query ComparisonTablePage {
    prismicComparisonTablePage {
      data {
        hero_title
        hero_sub_title {
          raw
        }

        intro_heading
        intro_text {
          raw
        }

        table_heading
        table_rows {
          table_row_title
          table_row_value_shipamax
          table_row_value_traditional
        }
        table_after {
          raw
        }

        ocr_heading
        ocr_content {
          raw
        }

        benefits_heading
        benefits_description {
          raw
        }
        benefits {
          benefit_title
          benefit_description {
            raw
          }
        }
        benefits_after {
          raw
        }

        cta_text
        cta_detail_text
        cta_button_label

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }

    prismicHomePage {
      data {
        ...SectionQuotesLegacy
      }
    }
  }
`;

export default function ComparisonTablePage({ data }: Props) {
  const page = data.prismicComparisonTablePage?.data;
  const homePage = data.prismicHomePage?.data;
  if (!page || !homePage) throw new Error("page/homePage missing");

  const renderValue = (value: string | undefined) => {
    if (value === "Yes") return <span className="glyph">✅</span>;
    if (value === "No") return <span className="glyph">❌</span>;
    return value;
  };

  return (
    <Layout footerBackColor={Color.darkBlue}>
      <HelmetSeo {...page} />

      <Hero
        title={page.hero_title || ""}
        subTitle={page.hero_sub_title?.raw}
        customBackground={<HeroCoverBackground src={HERO_BACKGROUND_1} />}
      />

      <StyledSectionIntro>
        <HorizontalContainer maxWidth={740}>
          <StyledIntroWrap>
            <h2>{page.intro_heading}</h2>
            <FormattedRichText render={page.intro_text?.raw} />
          </StyledIntroWrap>
        </HorizontalContainer>
      </StyledSectionIntro>

      <StyledSectionTable>
        <HorizontalContainer maxWidth={740}>
          <h2 style={{ textAlign: "center" }}>{page.table_heading}</h2>

          <StyledTable cellPadding={0} cellSpacing={0}>
            <thead>
              <tr>
                <th></th>
                <th className="shipamax">Shipamax</th>
                <th>Traditional OCR</th>
              </tr>
            </thead>
            <tbody>
              {page.table_rows?.map((row) => {
                if (!row) return null;
                return (
                  <tr key={row.table_row_title}>
                    <td className="title">{row.table_row_title}</td>
                    <td>{renderValue(row.table_row_value_shipamax)}</td>
                    <td>{renderValue(row.table_row_value_traditional)}</td>
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>

          <StyledTableAfter>
            <FormattedRichText
              onDark
              render={page.table_after?.raw}
              fontSize={20}
            />
          </StyledTableAfter>
        </HorizontalContainer>
      </StyledSectionTable>

      <SectionQuotes home={homePage} />

      <Section>
        <HorizontalContainer maxWidth={740}>
          <h2>{page.ocr_heading}</h2>
          <FormattedRichText render={page.ocr_content?.raw} />
        </HorizontalContainer>
      </Section>

      <StyledSectionBenefits>
        <HorizontalContainer>
          <div className="limit-width">
            <h2>{page.benefits_heading}</h2>
            <FormattedRichText onDark render={page.benefits_description?.raw} />
          </div>
          <StyledBenefits>
            {(page.benefits || []).map((benefit) => {
              if (!benefit) return null;
              return (
                <div key={benefit.benefit_title}>
                  <h3>{benefit.benefit_title}</h3>
                  <FormattedRichText
                    onDark
                    render={benefit.benefit_description?.raw}
                  />
                </div>
              );
            })}
          </StyledBenefits>
          <div className="limit-width">
            <FormattedRichText onDark render={page.benefits_after?.raw} />
          </div>
        </HorizontalContainer>
      </StyledSectionBenefits>
    </Layout>
  );
}

const StyledSectionIntro = styled(Section)`
  background-color: #f8f8f8;
`;

const StyledIntroWrap = styled.div`
  text-align: center;
`;

const StyledSectionTable = styled(Section)`
  background-color: ${Color.blue};
  color: #fff;
  background-image: url(${SECTION_BACKGROUND_DARK_1});
  background-size: cover;
`;

const StyledTable = styled.table`
  font-weight: 600;
  color: #000000;
  margin: 60px 0;

  th,
  td {
    background-color: ${Color.white};
    padding: 15px 18px;
    border: #828282 solid;
    border-width: 0 1px 1px 0;

    @media (max-width: 600px) {
      padding: 12px;
    }
  }

  th {
    font-size: 14px;
    text-align: center;

    &.shipamax {
      background-color: ${Color.orange};
      color: ${Color.white};
    }
  }

  td {
    background-color: ${Color.white};
    font-size: 12px;
    text-align: center;
    width: 30%;
    vertical-align: middle;

    &.title {
      background-color: ${Color.lightGrey};
      text-align: left;
      width: 40%;
    }

    span.glyph {
      font-size: 14px;
    }
  }
`;

const StyledTableAfter = styled.div`
  text-align: center;
`;

const StyledSectionBenefits = styled(Section)`
  background-color: ${Color.darkBlue};
  color: #fff;

  .limit-width {
    max-width: 520px;

    h2 {
      max-width: 330px;
    }
  }
`;

const StyledBenefits = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 15px;
  margin-top: 40px;

  > div {
    width: 50%;
    box-sizing: border-box;
    padding-right: 80px;
    margin-bottom: 20px;

    h3 {
      font-size: 15px;
      margin-bottom: 5px;
      color: ${Color.cyan};
    }

    @media (max-width: 900px) {
      width: 100%;
      padding-right: 0;
    }
  }
`;
